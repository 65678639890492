<template>
  <div style="height: 800px; overflow-y:scroll; overflow-x: hidden; ">
    <a-form :form="form" :layout="formLayout" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="商品总价">
            <a-input-number
              v-decorator="['goods_value', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="实际支付">
            <a-input-number
              v-decorator="['actural_paid', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="运杂费">
            <a-input-number
              v-decorator="['freight', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="⾮现⾦抵扣⾦额">
            <a-input-number
              v-decorator="['discount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="代扣税款">
            <a-input-number
              v-decorator="['tax_total', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订购⼈注册号">
            <a-input
              v-decorator="['buyer_reg_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订购⼈">
            <a-input
              v-decorator="['buyer_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订购⼈电话">
            <a-input
              v-decorator="['buyer_telephone', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订购⼈证件号">
            <a-input
              v-decorator="['buyer_id_number', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="收货人">
            <a-input
              v-decorator="['consignee', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="收货人电话">
            <a-input
              v-decorator="['consignee_telephone', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="收货地址">
            <a-input
              v-decorator="['consignee_address', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订单号">
            <a-input
              v-decorator="['order_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订单类型">
            <a-input
              v-decorator="['order_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="电商平台代码">
            <a-input
              v-decorator="['ebp_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="电商平台名称">
            <a-input
              v-decorator="['ebp_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="电商企业代码">
            <a-input
              v-decorator="['ebc_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="电商企业名称">
            <a-input
              v-decorator="['ebc_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider orientation="left">
        订单商品
      </a-divider>
      <a-table :columns="columns" :data-source="order_goods_list" bordered>
        <template
          v-for="col in ['item_name', 'gmodel', 'unit', 'qty','price', 'total_price', 'country']"
          :slot="col"
          slot-scope="text, record_item"
        >
          <div :key="col">
            <a-input
              v-if="record_item.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record_item.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record_item">
          <div class="editable-row-operations">
            <span v-if="record_item.editable">
              <a @click="() => save(record_item.id)">保存</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定取消吗?" @confirm="() => cancel(record_item.id)">
                <a>取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a :disabled="editingKey !== ''" @click="() => edit(record_item.id)">编辑</a>
            </span>
          </div>
        </template>
      </a-table>
      <a-row style="margin-bottom: 20px;">
        <a-col :span="14" v-if="true" style="text-align: center">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
        <a-col :span="10">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { customs_order_detail, customs_order_patch } from '@/api/customs_order'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      formLayout: 'horizontal',
      id: 0,
      private_tip: '备注：私玥内容此处不展示',
      order_goods_list: [],
      cacheData: [],
      editingKey: '',
      columns: [
        {
          title: '名称',
          dataIndex: 'item_name',
          width: '15%',
          scopedSlots: { customRender: 'item_name' }
        },
        {
          title: '规格型号',
          dataIndex: 'gmodel',
          width: '15%',
          scopedSlots: { customRender: 'gmodel' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: '10%',
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '数量',
          dataIndex: 'qty',
          width: '10%',
          scopedSlots: { customRender: 'qty' }
        },
        {
          title: '价格',
          dataIndex: 'price',
          width: '10%',
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '总价',
          dataIndex: 'total_price',
          width: '10%',
          scopedSlots: { customRender: 'total_price' }
        },
        {
          title: '国家',
          dataIndex: 'country',
          width: '15%',
          scopedSlots: { customRender: 'country' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    customs_order_detail(this.record.id)
      .then(({ data }) => {
        console.log(data)
        this.$nextTick(() => {
          if (data !== undefined) {
            this.loadEditInfo(data)

            if (data.order_goods_list) {
              this.order_goods_list = data.order_goods_list.map(item => {
                item.editable = false
                item.create_time = undefined
                item.last_update = undefined
                item.is_deleted = undefined
                item.e_order_id = undefined
                item.currency = undefined
                return item
              })

              this.cacheData = this.order_goods_list.map(item => ({ ...item }))
            }
          }
        })
      })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
        const requestData = { ...values, order_goods_list: this.order_goods_list }
        customs_order_patch(requestData, this.record.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
          })
      })
    },
    loadEditInfo (data) {
      console.log('===================', data)
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, [ 'goods_value', 'freight', 'discount', 'tax_total',
          'actural_paid', 'buyer_reg_no', 'buyer_name', 'buyer_id_number', 'buyer_telephone', 'consignee',
          'consignee_telephone', 'consignee_address', 'corporation_id', 'order_type', 'order_no',
        'ebp_code', 'ebp_name', 'ebc_code', 'ebc_name', 'currency', ''])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    edit (key) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.order_goods_list = newData
      }
    },
    save (key) {
      const newData = [...this.order_goods_list]
      const newCacheData = [...this.cacheData]
      const target = newData.find(item => key === item.id)
      const targetCache = newCacheData.find(item => key === item.id)
      if (target && targetCache) {
        delete target.editable
        this.order_goods_list = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.id))
        delete target.editable
        this.order_goods_list = newData
      }
    },
    handleChange (value, key, column) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      if (target) {
        target[column] = value
        this.order_goods_list = newData
      }
    }
  }
}
</script>
